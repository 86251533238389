<template>
  <ul class="menu-nav">

    <!-- Home -->
    <router-link
      v-if="showmenu"
      :to="`${walleturl}`"
      v-slot="{ href, navigate, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">Wallet Home</span>
        </a>
      </li>
    </router-link>

    <!-- ทายผล -->
    <router-link
      v-if="showmenu"
      :to="`${walleturl}/predict`"
      v-slot="{ href, navigate, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">ทายผล</span>
        </a>
      </li>
    </router-link>

    <!-- ตารางแข่ง -->
    <router-link
      v-if="showmenu"
      :to="`${walleturl}/fixtures`"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">ตารางแข่ง</span>
        </a>
      </li>
    </router-link>

    <!-- Leaderboard -->
    <router-link
      v-if="showmenu"
      :to="`${walleturl}/leaderboard`"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">Leaderboard</span>
        </a>
      </li>
    </router-link>

    <!-- ของรางวัล -->
    <router-link
      v-if="showmenu"
      :to="`${walleturl}/rewards`"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">ของรางวัล</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
export default {
  name: "KTMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  },
  computed:{
    game(){
      return this.$route.params.game
    },
    tour(){
      return this.$route.params.tour
    },
    wallet(){
      return this.$route.params.wallet
    },
    showmenu(){
      // return this.game != null && this.tour != null
      return this.wallet != null
    },
    gameurl(){
      return `/games/${this.game}/tours/${this.tour}`
    },
    walleturl() {
      return `/wallets/${this.wallet}`
    }
  }
};
</script>

<style lang="scss" scoped>
.menu-item-active > .menu-link {
  .menu-text {
    color: #FFFFFF !important;
  }

  background-color: #71aaff !important;
}
</style>