<template>
  <div class="aside aside-left d-flex flex-column" id="kt_aside" ref="kt_aside">
    <!--begin::Brand-->
    <KTBrand></KTBrand>
    <!--end::Brand-->
    <!--begin::Nav Wrapper-->
    <div
      class="aside-nav d-flex flex-column align-items-center flex-column-fluid pt-7"
    >
      <!--begin::Nav-->
      <ul class="nav flex-column">
        <!--begin::Item-->
        <li v-for="wallet in wallets" v-bind:key="wallet.no" class="nav-item d-flex flex-column align-items-center mb-5">
          <router-link
            :to="`/wallets/${wallet.id}`"
            class="nav-link btn btn-icon btn-clean btn-icon-white btn-lg text-white"
            v-bind:class="{active:link(`/wallets/${wallet.id}`)}"
          >
            <div class="symbol symbol-50">
              <span class="symbol-label font-size-h6 font-weight-bold bg-transparent">
                <img v-if="walletIcon(wallet)!==''" :src="walletIcon(wallet)" class="w-75 h-75 align-self-center" />
                <span class="text-white" v-else>{{ wallet.id }}</span>
              </span>
            </div>
          </router-link>
          <div class="mt-1">
            <b-badge variant="dark"><span class="text-center">{{ num(wallet) }}</span></b-badge>
          </div>
        </li>
        <!--end::Item-->
      </ul>
      <!--end::Nav-->
    </div>
    <!--end::Nav Wrapper-->
    <!--begin::Footer-->
    <div
      class="aside-footer d-flex flex-column align-items-center flex-column-auto py-8"
    >
    </div>
    <!--end::Footer-->
  </div>
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
import KTBrand from "@/view/layout/brand/Brand";
import KTLayoutAside from "@/assets/js/layout/base/aside.js";

import { UPDATE_WALLET_INFO } from "@/core/services/store/profile.module";

export default {
  name: "Aside",
  components: {
    KTBrand
  },
  data () {
    return {
      // wallets: []
    }
  },
  methods: {
    recvWallets() {
      this.axios.get('/wallets').then(
        ({ data }) => {
          var wallets = data.wallets || []
          wallets.forEach(w => {
            this.$set(w, 'points', data.points[w.no] || -1 )
            this.$set(w, 'time', data.nexttime[w.no] || -1)
          })
          this.$store.dispatch(UPDATE_WALLET_INFO, wallets)
        }
      )
    },
    link (wallet) {
      return this.$route.path.indexOf(wallet) > -1
    },
    walletIcon (wallet) {
      return wallet.img || ''
    },
    num (wallet) {
      var points = wallet.points

      if (points === -1) {
        return '-'
      }

      if (points > 1000000000000) {
        return (points * 0.000000000001).toFixed(1) + 'T'
      } else if (points > 1000000000) {
        return (points * 0.000000001).toFixed(1) + 'B'
      } else if (points > 1000000) {
        return (points * 0.000001).toFixed(1) + 'M'
      } else if (points > 1000) {
        return (points * 0.001).toFixed(1) + 'K'
      }

      return points
    }
  },
  computed: {
    auth() {
      return this.$store.state.auth.isAuthenticated || false
    },
    wallets() {
      return this.$store.getters['currentWallet']
    }
  },
  watch: {
    auth (auth) {
      if (auth) {
        console.log('auth')
        this.recvWallets()
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      // Init Aside
      KTLayoutAside.init(this.$refs["kt_aside"]);
      this.recvWallets();
    });
  }
};
</script>
