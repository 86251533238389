<template>
  <div class="topbar-item">
    <div
      class="btn btn-icon btn-clean w-auto d-flex align-items-center btn-lg px-2"
      id="kt_quick_user_toggle"
    >
      <div class="d-flex flex-column text-right pr-3">
        <span
          class="font-weight-bold font-size-base d-none d-md-inline"
          >{{ member }}</span
        >
        <span
          class="font-size-base d-none d-md-inline"
          >{{ name }}</span
        >
      </div>
      <span class="symbol symbol-35 symbol-light-primary">
        <img v-if="img" :src="img" class="w-100 h-100" />
        <span v-else class="symbol-label font-size-h5 font-weight-bold">{{ name[0] }}</span>
      </span>
    </div>

    <div v-if="guest" class="btn btn-primary w-auto d-flex align-items-center px-2 ml-4" @click="goLogin">
      <span class="font-size-h6 px-3">Login</span>
    </div>

    <div v-if="guest" class="btn btn-primary w-auto d-flex align-items-center px-2 ml-2" @click="goRegister">
      <span class="font-size-h6 px-3">Register</span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">
          <span v-if="guest">Welcome to Tryfree</span>
          <span v-else>User Profile</span>
          <!-- <small class="text-muted font-size-sm ml-2">12 messages</small> -->
        </h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-danger btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative;"
      >
        <div v-if="guest">
          <!--begin::Nav-->
          <div class="navi navi-spacer-x-0 p-0">
            <!--begin::Item-->
            <router-link
              to="/login"
              @click.native="closeOffcanvas"
              class="navi-item"
            >
              <div class="navi-link">
                <div class="symbol symbol-40 bg-light mr-3">
                  <div class="symbol-label">
                    <span class="svg-icon svg-icon-md svg-icon-success">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="media/svg/icons/General/Lock.svg"
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </div>
                </div>
                <div class="navi-text">
                  <div class="font-weight-bold">Login</div>
                </div>
              </div>
            </router-link>
            <!--end:Item-->
            <!--begin::Item-->
            <router-link
              to="/register"
              @click.native="closeOffcanvas"
              class="navi-item"
            >
              <div class="navi-link">
                <div class="symbol symbol-40 bg-light mr-3">
                  <div class="symbol-label">
                    <span class="svg-icon svg-icon-md svg-icon-success">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="media/svg/icons/Design/Edit.svg"
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </div>
                </div>
                <div class="navi-text">
                  <div class="font-weight-bold">Register</div>
                </div>
              </div>
            </router-link>
            <!--end:Item-->
          </div>
          <!--end::Nav-->
        </div>
        <div v-else>
          <!--begin::Header-->
          <div class="d-flex align-items-end mt-5">
            <div class="symbol symbol-100 mr-5">
              <img v-if="img" :src="img" class="w-100 h-100 bg-dark" />
              <div v-else class="symbol-label bg-dark"></div>
              <!-- <img class="symbol-label" :src="picture" alt="" /> -->
              <!-- <i class="symbol-badge bg-success"></i> -->
            </div>
            <div class="d-flex flex-column">
              <div class="font-weight-bold font-size-h5">
                {{ name }}
              </div>
              <button class="mt-5 btn btn-light-primary btn-bold" @click="modalLogout=true">
                Sign out
              </button>
            </div>
          </div>
          <!--end::Header-->
          <div class="separator separator-dashed mt-8 mb-2"></div>
          <!--begin::Nav-->
          <div class="navi navi-spacer-x-0 p-0">
            <!--begin::Item-->
            <router-link
              to="/profile"
              @click.native="closeOffcanvas"
              class="navi-item"
            >
              <div class="navi-link">
                <div class="symbol symbol-40 mr-3">
                  <div class="symbol-label bg-dark">
                    <span class="svg-icon svg-icon-md svg-icon-success">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="media/svg/icons/General/Notification2.svg"
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </div>
                </div>
                <div class="navi-text">
                  <div class="font-weight-boldest">My Profile</div>
                  <div class="text-muted">
                    Account settings
                  </div>
                </div>
              </div>
            </router-link>
            <!--end:Item-->
          </div>
          <!--end::Nav-->
          <div class="separator separator-dashed my-2"></div>
          <!--begin::Nav-->
          <div class="navi navi-spacer-x-0 p-0" v-for="wallet in wallets" v-bind:key="wallet.no">
            <!--begin::Item-->
            <div
              class="navi-item"
            >
              <div class="navi-link">
                <div class="symbol symbol-40 mr-3">
                  <span class="symbol-label font-size-h6 font-weight-bold bg-transparent">
                    <img v-if="walletIcon(wallet)!==''" :src="walletIcon(wallet)" class="w-100 h-100 align-self-center" />
                    <span class="text-white" v-else>{{ wallet.id }}</span>
                  </span>
                </div>
                <div class="navi-text">
                  <div class="font-weight-bold">{{ wallet.name }}</div>
                  <div v-if="wallet.points > -1">{{ wallet.points }} pts</div>
                </div>
                <div class="navi-label">
                  <b-btn v-if="counting(wallet)" size="sm">{{ countdown(wallet) }}</b-btn>
                  <b-btn v-else size="sm" @click="callGetpoint(wallet.id)">รับแต้ม</b-btn>
                </div>
              </div>
            </div>
            <!--end:Item-->
          </div>
        </div>
      </perfect-scrollbar>
      <!--end::Content-->
    </div>

    <!-- Logout -->
    <b-modal id="modal-logout" v-model="modalLogout" hide-header @ok="onLogout">
      <div class="h4">Logout</div>
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import dayjs from 'dayjs'

import { LOGOUT, SHOW_DIALOG, SHOW_MODE } from "@/core/services/store/auth.module";
import { UPDATE_WALLET_POINT } from "@/core/services/store/profile.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";

export default {
  name: "KTQuickUser",
  data() {
    return {
      list: [
        // {
        //   title: "Another purpose persuade",
        //   desc: "Due in 2 Days",
        //   alt: "+28%",
        //   svg: "media/svg/icons/Home/Library.svg",
        //   type: "warning"
        // },
        // {
        //   title: "Would be to people",
        //   desc: "Due in 2 Days",
        //   alt: "+50%",
        //   svg: "media/svg/icons/Communication/Write.svg",
        //   type: "success"
        // },
        // {
        //   title: "Purpose would be to persuade",
        //   desc: "Due in 2 Days",
        //   alt: "-27%",
        //   svg: "media/svg/icons/Communication/Group-chat.svg",
        //   type: "danger"
        // },
        // {
        //   title: "The best product",
        //   desc: "Due in 2 Days",
        //   alt: "+8%",
        //   svg: "media/svg/icons/General/Attachment2.svg",
        //   type: "info"
        // }
      ],
      timenow: dayjs().valueOf(),
      clock: null,
      modalLogout: false
    };
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
    this.startClock()
  },
  methods: {
    startClock() {
      this.clock = setInterval(() => {
        this.timenow = dayjs().valueOf()
      }, 1000)
    },
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    walletIcon (wallet) {
      return wallet.img || ''
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
    counting(wallet) {
      return wallet.time > -1 && this.timenow < wallet.time
    },
    countdown(wallet) {
      var time = wallet.time - this.timenow
      var seconds = ~~(time * 0.001)
      var sec = seconds % 60
      var minutes = (seconds - sec) / 60
      var min = minutes % 60
      var hours = (minutes - min) / 60
      return `${hours}:${min>9?min:'0'+min}:${sec>9?sec:'0'+sec}`
    },
    callGetpoint(wallet) {
      this.axios.post(`/wallets/${wallet}/getpoints`).then(
        ({ data }) => {
          this.$store.dispatch(UPDATE_WALLET_POINT, {
            no: data.no,
            points: data.points,
            time: data.nextgen
          })
        }
      )
    },
    goLogin() {
      // this.$router.push('/login')
      this.$store.dispatch(SHOW_DIALOG, true)
    },
    goRegister() {
      // this.$router.push('/register')
      this.$store.dispatch(SHOW_MODE, 'register')
    }
  },
  computed: {
    picture() {
      // return process.env.BASE_URL + "media/users/300_21.jpg";
      return ''
    },
    guest () {
      return this.user.name == null
    },
    user () {
      return this.$store.getters['currentUser'] || {}
    },
    member() {
      return this.guest ? 'Guest' : (this.user.verify ? 'Verified Member' : 'Member')
    },
    name() {
      return this.user.name || 'No name'
    },
    wallets() {
      return this.$store.getters['currentWallet']
    },
    img() {
      return this.user.img || false
    }
  },
  beforeDestroy() {
    if (this.clock) {
      clearInterval(this.clock)
    }
  }
};
</script>
