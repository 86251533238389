<template>
  <div
    id="kt_header_mobile"
    ref="kt_header_mobile"
    class="header-mobile header-mobile-fixed"
    v-bind:class="headerClasses"
  >
    <!--begin::Logo-->
    <a href="/">
      <img
        alt="Logo"
        src="/images/logo1.png"
        class="logo-default"
        style="max-height:40px"
      />
    </a>
    <!--end::Logo-->
    <!--begin::Toolbar-->
    <div class="d-flex align-items-center menutabs">
      <!-- Help -->
      <!-- <router-link to="/about">
        <button class="btn btn-primary d-inline-flex align-items-center">
          <i class="far fa-question-circle pr-0" />
        </button>
      </router-link> -->
      <!--begin::Aside Mobile Toggle-->
      <button
        v-if="asideEnabled"
        class="btn btn-primary d-inline-flex align-items-center ml-2"
        id="kt_aside_mobile_toggle"
        ref="kt_aside_mobile_toggle"
      >
        <div v-if="walletinfo" class="symbol symbol-20 d-inline-flex align-items-center">
          <span class="symbol-label bg-transparent mr-2" :style="walleticon"></span>{{ walletpoints }}
        </div>
        <span v-else>เลือกเหรียญทายผล</span>
        <i class="ml-2 pr-0 fas fa-caret-down" />
      </button>
      <button
        v-show="canGetPoint"
        class="btn get ml-2"
        id="kt_aside_getpoint_toggle"
        ref="kt_aside_getpoint_toggle"
      >
        <span>รับแต้มได้แล้ว</span>
      </button>
      <!--end::Aside Mobile Toggle-->
      <!--begin::Header Menu Mobile Toggle-->
      <!-- <button
        class="btn p-0 burger-icon ml-4"
        id="kt_header_mobile_toggle"
        ref="kt_header_mobile_toggle"
      >
        <span> </span>
      </button> -->
      <!--end::Header Menu Mobile Toggle-->
      <!--begin::Topbar Mobile Toggle-->
      <span v-show="user.name" class="symbol symbol-35 symbol-dark ml-2"
        id="kt_header_mobile_topbar_toggle"
        ref="kt_header_mobile_topbar_toggle"
      >
        <img v-if="img" :src="img" class="w-100 h-100" />
        <span v-else class="symbol-label font-size-h5 font-weight-bold">{{ name[0] }}</span>
      </span>
      <button
        v-show="!user.name"
        class="btn btn-primary ml-2"
        id="kt_header_mobile_login_toggle1"
        ref="kt_header_mobile_login_toggle1"
        v-on:click="showLogin"
      >
        <span>Login / Register</span>
      </button>
      <!--end::Topbar Mobile Toggle-->
    </div>
    <!--end::Toolbar-->
  </div>
</template>

<script>
import dayjs from 'dayjs'

import { mapGetters } from "vuex";
import KTLayoutHeaderTopbar from "@/assets/js/layout/base/header-topbar.js";

import { SHOW_DIALOG, SHOW_REGISTER } from "@/core/services/store/auth.module";

export default {
  name: "KTHeaderMobile",
  components: {},
  mounted() {
    // Init Header Topbar For Mobile Mode
    // KTLayoutHeaderTopbar.init(this.$refs["kt_header_mobile_topbar_toggle"]);
    this.startClock()
  },
  data() {
    return {
      timenow: dayjs().valueOf(),
      clock: null
    }
  },
  methods: {
    startClock() {
      this.clock = setInterval(() => {
        this.timenow = dayjs().valueOf()
      }, 1000)
    },
    showLogin() {
      this.$store.dispatch(SHOW_DIALOG, true)
    }
  },
  beforeDestroy() {
    if (this.clock) {
      clearInterval(this.clock)
    }
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Get header logo
     * @returns {string}
     */
    headerLogo() {
      return process.env.BASE_URL + this.layoutConfig("self.logo");
    },

    /**
     * Get classes for mobile header
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header_mobile");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return this.layoutConfig("aside.self.display");
    },
    user () {
      return this.$store.getters['currentUser'] || {}
    },
    img() {
      return this.user.img || false
      // return false
    },
    name() {
      return this.user.name || 'Guest'
    },
    currentwallet() {
      return this.$route.params.wallet || ''
    },
    walletinfo() {
      return this.$store.getters['currentWallet'].find(w => w.id === this.currentwallet) || null
    },
    walleticon() {
      if (this.walletinfo && this.walletinfo.img) {
        return {
          'background-image': `url(${this.walletinfo.img})`
        }
      }

      return {}
    },
    walletpoints() {
      var points = this.walletinfo.points

      if (points === -1) {
        return '-'
      }

      if (points > 1000000000000) {
        return (points * 0.000000000001).toFixed(1) + 'T'
      } else if (points > 1000000000) {
        return (points * 0.000000001).toFixed(1) + 'B'
      } else if (points > 1000000) {
        return (points * 0.000001).toFixed(1) + 'M'
      } else if (points > 1000) {
        return (points * 0.001).toFixed(1) + 'K'
      }

      return points
    },
    wallets() {
      return this.$store.getters['currentWallet']
    },
    canGetPoint() {
      return this.wallets.some(v => v.points > -1 && v.time < this.timenow) && this.asideEnabled
    }
  }
};
</script>

<style lang="scss" scoped>
.get {
  background-color: #9c8400;
}

.menutabs {
  .btn {
    height: 35px;
  }
}
</style>