<template>
  <b-modal v-model="modalLogin" content-class="shadow" hide-header hide-footer centered :no-close-on-backdrop="noclose">
    <!-- Login -->
    <div v-if="mode==='login'">
      <div class="h3 mb-8">Login</div>
      <div class="mb-4">
        <b-form-input v-model="form.login.email" class="primary" placeholder="E-Mail Address" />
      </div>
      <div class="mb-2">
        <b-form-input type="password" v-model="form.login.password" class="primary" placeholder="Password" />
      </div>
      <div class="text-danger mt-2 mb-2">{{ password_invalid }}</div>
      <div class="text-center mt-4 mb-10">
        <b-btn variant="primary" @click="callLogin">Login</b-btn>
      </div>
      <div class="mb-8">
        <span class="link" @click="showRegister">ลงทะเบียนได้ที่นี่</span> หรือ <span class="link" @click="showForget">ลืมรหัสผ่าน</span>
      </div>
      <div>
        <span class="mr-2">Login ด้วย Social Network</span>
        <span class="mr-2"><b-btn variant="primary" size="sm" @click="loginFacebook"><span class="fab fa-facebook-f mr-2" />Facebook</b-btn></span>
        <span><b-btn variant="primary" size="sm" @click="loginGoogle"><span class="fab fa-google mr-2" />Google</b-btn></span>
      </div>
    </div>
    <!-- Register -->
    <div v-if="mode==='register'">
      <div class="h3 mb-8">Register</div>
      <div class="mb-4">
        <b-form-input v-model="form.login.email" class="primary" placeholder="E-Mail Address" />
      </div>
      <div class="text-center mb-10">
        <b-btn variant="primary" @click="callRegister">Register</b-btn>
      </div>
      <div class="mb-8">
        <span class="link" @click="showLogin">เข้าสู่ระบบได้ที่นี่</span> หรือ <span class="link" @click="showForget">ลืมรหัสผ่าน</span>
      </div>
      <div>
        <span class="mr-2">ลงทะเบียนด้วย Social Network</span>
        <span class="mr-2"><b-btn variant="primary" size="sm" @click="registerFacebook"><span class="fab fa-facebook-f mr-2" />Facebook</b-btn></span>
        <span><b-btn variant="primary" size="sm" @click="registerGoogle"><span class="fab fa-google mr-2" />Google</b-btn></span>
      </div>
    </div>
    <!-- Forget Password -->
    <div v-if="mode==='forget'">
      <div class="h3 mb-8">Forget Password</div>
      <div class="mb-4">
        <b-form-input v-model="form.login.email" class="primary" placeholder="E-Mail Address" />
      </div>
      <div class="text-center mb-10">
        <b-btn variant="primary" @click="callReset">Reset</b-btn>
      </div>
      <div class="">
        <span class="link" @click="showLogin">เข้าสู่ระบบได้ที่นี่</span> หรือ <span class="link" @click="showRegister">ลงทะเบียนใหม่</span>
      </div>
    </div>
    <!-- Register OK -->
    <div v-if="mode==='registerok'">
      <div class="h3 mb-8">Register</div>
      <div class="mb-4">อีเมล์ยืนยันการลงทะเบียนจะถูกส่งไปยัง {{ form.login.email }}</div>
      <div class="text-center">
        <b-btn variant="primary" @click="modalLogin=false">Close</b-btn>
      </div>
    </div>
    <!-- Reset OK -->
    <div v-if="mode==='resetok'">
      <div class="h3 mb-8">Forget Password</div>
      <div class="mb-4">อีเมล์วิธีการเปลี่ยนรหัสผ่านจะถูกส่งไปยัง {{ form.login.email }}</div>
      <div class="text-center">
        <b-btn variant="primary" @click="modalLogin=false">Close</b-btn>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { LOGIN, LOGOUT, REGISTER, VERIFY_AUTH, SHOW_DIALOG, SHOW_MODE } from "@/core/services/store/auth.module";

import JwtService from "@/core/services/jwt.service";

import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  data() {
    return {
      noclose: false,
      form: {
        login: {
          email: '',
          password: ''
        }
      },
      password_invalid: ''
      // mode: 'login'
    }
  },
  methods: {
    showLogin() {
      this.mode = 'login'
      this.password_invalid = ''
    },
    callLogin() {
      this.password_invalid = ''

      this.$store
        .dispatch(LOGIN, this.form.login)
        // go to which page after successfully login
        .then(this.loadUser)
        .catch((data) => {
          console.error('error', data.data.failed)
          switch (data.data.failed) {
            case 'user':
            case 'password_invalid':
              this.password_invalid = 'ชื่อบัญชีผู้ใช้หรือรหัสผ่านไม่ถูกต้อง'
              break
            case 'password_short':
              this.password_invalid = 'ระบุรหัสผ่านด้วย'
              break
          }
        })
    },
    loginGoogle() {
      this.$router.push('/login?auth=google')
      var provider = new firebase.auth.GoogleAuthProvider()
      firebase.auth().signInWithRedirect(provider)
    },
    loginFacebook() {
      this.$router.push('/login?auth=facebook')
      var provider = new firebase.auth.FacebookAuthProvider()
      firebase.auth().signInWithRedirect(provider)
    },
    showRegister () {
      this.mode = 'register'
    },
    registerFacebook() {
      this.$router.push('/register?auth=facebook')
      var provider = new firebase.auth.FacebookAuthProvider()
      firebase.auth().signInWithRedirect(provider)
    },
    registerGoogle() {
      this.$router.push('/register?auth=google')
      var provider = new firebase.auth.GoogleAuthProvider()
      firebase.auth().signInWithRedirect(provider)
    },
    showForget () {
      this.mode = 'forget'
    },
    callRegister() {
      var actionCodeSettings = {
        url: process.env.VUE_APP_CONFIRM_URL,
        handleCodeInApp: true,
        // dynamicLinkDomain: "localhost"
      }

      this.$store.dispatch(LOGOUT)

      var email = this.form.login.email
      this.noclose = true

      firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings)
      .then(() => {
        window.localStorage.setItem('emailForSignIn', email);
        this.mode = 'registerok'
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.error(errorCode, errorMessage)
        // this.signup_errortxt = errorMessage
        // this.signup = 9
        this.noclose = false
      });
    },
    callReset() {
    },
    loadUser() {
      this.$store.dispatch(VERIFY_AUTH)
      this.modalLogin = false
    }
  },
  computed: {
    modalLogin: {
      set(v) {
        this.$store.dispatch(SHOW_DIALOG, v)
      },
      get() {
        return this.$store.getters['showLogin']
      }
    },
    mode: {
      set(v) {
        this.$store.dispatch(SHOW_MODE, v)
      },
      get() {
        return this.$store.getters['showMode']
      }

    }
  }
}
</script>

<style lang="scss" scoped>
.primary::placeholder {
  color: #808080;
}

.link {
  &:hover {
    cursor: pointer;
    color: #ac83ff;
  }
}
</style>