<template>
  <div class="topbar">
    <!--begin: Notifications -->
    <!-- <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <div
          class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse pulse-primary"
        >
          <span class="svg-icon svg-icon-xl">
            <inline-svg src="media/svg/icons/Code/Compiling.svg" />
          </span>
          <span class="pulse-ring"></span>
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <form>
          <KTDropdownNotification></KTDropdownNotification>
        </form>
      </b-dropdown-text>
    </b-dropdown> -->
    <!--end: Notifications -->
    <!-- <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <div
          class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse pulse-primary"
        >
          <span class="svg-icon svg-icon-xl">
            <inline-svg src="media/svg/icons/Code/Compiling.svg" />
          </span>
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <div class="p-4">
          <b-btn variant="primary" @click="callGetpoint">รับแต้ม</b-btn>
        </div>
      </b-dropdown-text>
    </b-dropdown> -->
    <!-- <div class="topbar-item mr-5">
      <router-link to="/about">
        <button class="topbar-item btn btn-primary d-inline-flex align-items-center">
          <i class="far fa-question-circle pr-0" />
        </button>
      </router-link>
    </div> -->
    <div class="topbar-item mr-5" v-show="canGetPoint">
      <span class="btn get d-flex align-items-center pulse pulse-primary" id="kt_getpoint">
        <div class="symbol symbol-25 mr-3">
          <img alt="Pic" src="/images/getpoints.png"/>
        </div>รับแต้มได้แล้ว
      </span>
    </div>

    <!--begin::User-->
    <KTQuickUser></KTQuickUser>
    <!--end::User-->
  </div>
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import dayjs from 'dayjs'
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser";
// import KTDropdownNotification from "@/view/layout/extras/dropdown/DropdownNotification.vue";

export default {
  name: "Topbar",
  components: {
    KTQuickUser,
    // KTDropdownNotification
  },
  methods: {
    startClock() {
      this.clock = setInterval(() => {
        this.timenow = dayjs().valueOf()
      }, 1000)
    }
  },
  computed: {
    canGetPoint() {
      return this.wallets.some(v => v.points > -1 && v.time < this.timenow)
    },
    wallets() {
      return this.$store.getters['currentWallet']
    }
  },
  mounted() {
    this.startClock()
  },
  data() {
    return {
      clock: null,
      timenow: dayjs().valueOf()
    }
  },
  beforeDestroy() {
    if (this.clock) {
      clearInterval(this.clock)
    }
  }
};
</script>

<style lang="scss" scoped>
.get {
  background-color: #9c8400;
}

.topbar {
  .btn {
    height: 45px;
  }
}
</style>